.consent {
  color: white;
  background-color: black;
  height: 100vh;
  padding: 20px;
}
.consent h2 {
  font-family: Zeniq, serif;
}


p {
  text-align: justify;
}

// for numbers in the "event highlight" headings
h2 {
  font-family: 'Audiowide', cursive;
}

h1, h2 {
  text-align: center;
}

a {
  color: #b40000;
  text-decoration: none;
}
img {
  border-radius: 20px;
}

a:hover {
  color: #af8282;
}

// header

// landing
.App {
  //font-family: 'Audiowide', cursive;
  font-family: 'Zeniq', serif;
  color: white;
}

.intro {
  height: 100vh; /* 100% of screen */
}

.intro-content {
  text-align: center;
  position: absolute;
  color: whitesmoke;
}

.intro-title {
  font-size: 7vw;
  font-weight: 700;
  margin: 0;
}

.table-cell canvas {
  width: 75vw;
  height: 300px;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.landing--background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  opacity: 0.9;
}

.landing--background-video-mobile {
  display: none;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #b40000;
  z-index: -2;
}

.landing--background-audio-control {
  border: unset;
  background-color: unset;
  color: unset;
  font-size: unset;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.landing--background-audio-control:hover {
  color: #a6a6ff;
}

@media only screen and (max-width: 850px) {
  .intro-title {
    font-weight: 700;
    margin: 0;
  }
  .table-cell canvas {
    width: 80%;
    height: 20%;
  }
}

// about

.row {
  margin-bottom: 3rem;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: 10px;
  row-gap: 10px;
  padding: 0 10rem;
}

.col {
  padding: 20px 40px 40px 40px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.8);
}

.members {
  display: flex;
  flex-direction: column;
}

.member {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.member img {
  border-radius: 100%;
  width: calc(min(10vw, 10vh));
  height: calc(min(10vw, 10vh));
}

.member-info {
  text-align: center;
  font-size: calc(max(1vw, 20px));
  margin: auto 0 auto 10px;
  padding: 10px;
}

.react-player {
  padding-top: 20px;
  width: 100% !important;
  height: 100px !important;
}

// contact

.contact-form {
  align-content: center;
}

.consent-button,
.contact-form input, textarea {
  font-family: inherit;
  color: rgb(255, 255, 255);
  background-color: transparent;
  width: 100%;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 10px;
  box-sizing: border-box; /* otherwise the inputs are longer than the div */
  -webkit-appearance: none;
}

.consent-button,
.contact-form input:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 5px 0 #b40000;
  border-color: #b40000;
}

::placeholder {
  font-weight: 350;
  color: rgb(162, 162, 162);
}

.consent-button,
.contact-form input[type=submit] {
  background-color: rgba(180, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-appearance: none;
}

.consent-button:hover,
.contact-form input[type=submit]:hover {
  background-color: #b40000;
  cursor: pointer;
}

.contact-form textarea {
  height: 100px;
}

.contact-form-alert {
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.contact-form-alert-success {
  background-color: rgba(58, 243, 60, 0.5);
  color: #ffffff;
}

.contact-form-alert-failure {
  background-color: rgba(180, 0, 0, 0.5);
  color: #ffffff;
}

.contact-form-alert-close-button {
  margin-left: 15px;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-form-alert-close-button-success {
  color: #ffffff;
}

.contact-form-alert-close-button-failure {
  color: #ffffff;
}

.contact-form-alert-close-button-success:hover {
  color: rgba(255, 255, 255, 0.5);
}

.contact-form-alert-close-button-failure:hover {
  color: rgba(255, 255, 255, 0.5);
}

/* Workaround for issue with autofill colors of Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #333333 inset;
  -webkit-text-fill-color: rgb(196, 196, 196);
  caret-color: rgb(196, 196, 196);
}

.highlight {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -3;
}


/* ==============
 * Navigation Bar
 * ==============
 */

.no-navbar {
  display: none;
}

.navbar {
  width: calc(100% - 20rem);
  padding-top: 0.5rem;
  padding-left: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
}

.navbar--sticky {
  background: #000000;
  /* background: #333333; */
  position: fixed;
  padding: 0.5rem 10rem;
  top: 0;
  left: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow: 1px 2px 1px #222222; */
  animation: moveDown 0.5s ease-in-out;
}

.navbar--logo-holder {
  font-family: 'Zeniq', serif;
  display: flex;
  align-items: center;
  height: 30px;
}

.navbar--logo-holder:hover {
  cursor: pointer;
}

.navbar--logo {
  height: 50px; // TODO make height 100% here
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--logo-text-sticky {
  color: #ffffff;
  margin: 0;
}

.navbar--link {
  display: flex;
}

.navbar--link a,
.navbar--link a:hover {
  color: white;
}

.active-page:before {
  margin-right: 5rem;
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  background: #000;
  height: 1px;
  left: 0;
  right: 0;
  width: 100%;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.active-page:focus:before {
  left: 0;
  right: auto;
  width: 100%;
  animation: moveRight, 0.3s, ease-in-out, 0.3s;
}

.navbar--link-item-sticky {
  color: #ffffff;
  font-size: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
}

/* =====================================================
 * Disable the link hover color change for the nav links
 * =====================================================
 */
.navbar--link-item-sticky:hover,
.navbar--link-item:hover {
  color: whitesmoke;
}

/* ==================================
 * Underline effect for the nav items
 * ==================================
 */
.navbar--link-item-sticky:after,
.navbar--link-item:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: 0;
  background: #ffffff;
  height: 1px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.navbar--link-item:hover:after,
.navbar--link-item:focus:after,
.navbar--link-item:active:after,
.navbar--link-item-sticky:hover:after,
.navbar--link-item-sticky:focus:after,
.navbar--link-item-sticky:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* =============
 * Mobile Navbar
 * =============
 */

.mobile-navbar {
  position: fixed;
  display: none;
  width: calc(100% - 1rem);
  padding: 0.5rem 0.5rem;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
}

.mobile-navbar-sticky {
  position: fixed;
  display: none;
  background: #000000;
  padding: 0.5rem 0.5rem;
  top: 0;
  left: 0;
  /* box-shadow: 1px 2px 1px #222222; */
  animation: moveDown 0.5s ease-in-out;
  z-index: 5;
}

.mobile-navbar--logo-holder {
  align-items: center;
}

.mobile-navbar--logo-holder:hover {
  cursor: pointer;
}

.mobile-navbar--logo {
  height: 40px;
  padding-top: 5px;
  padding-left: 6px;
}

.dropdown-items {
  position: fixed;
  margin-top: 8px;
  padding-bottom: 8px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000000;
}

.dropdown-items button {
  background-color: #000000;
  color: #ffffff;
  font: inherit;
  font-size: 2.5vmax;
  margin: 0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  outline: none; // remove ugly focus/border after click
}

.dropdown-items button:hover {
  background-color: #280e0e;
  color: #af8282;
  cursor: pointer;
}

.dropdown-items button:active {
  background-color: #280e0e;
}

.active-mobile-page {
  font-weight: 700;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding-top: 5px;
  overflow: visible;
  outline: none;
}

.hamburger:hover {
  opacity: 0.8;
}

.hamburger.is-active:hover {
  opacity: 0.8;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 50px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner-sticky, .hamburger-inner-sticky::before, .hamburger-inner-sticky::after {
  background-color: #ffffff;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

// Small Desktop
@media only screen and (min-width: 1100px) and (max-width: 1750px) {
  .navbar {
    width: calc(100% - 10rem);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5rem;
  }
  .navbar--sticky {
    padding-top: 0;
    padding-bottom: 1px;
    padding-left: 5rem;
  }
  .row {
    padding: 0 5rem;
  }
}

// Smaller width than 1100px in general (tablets and mobiles)
@media only screen and (max-width: 1400px) {
  .navbar {
    display: none;
  }
  .navbar--sticky {
    display: none;
  }
  .mobile-navbar {
    display: flex;
  }
  .mobile-navbar-sticky {
    display: flex;
  }
  .row {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    padding: 0 5rem;
  }
  .col {
    padding: 20px 50px 50px 50px;
  }
  .member-info {
    font-size: calc(max(2vw, 17px));
  }
  .member {
    margin: auto;
  }
}

// about

// contact

// impression

// paypal

.paypal-button {
  margin: auto;
  width: fit-content;
}

// footer

.footer {
  color: #ffffff;
  text-align: center;
  opacity: 0.9;
}

.footer a {
  color: #ffffff;
  text-decoration: underline;
}

.footer a:hover {
  color: #b9b9b9;
}

@media only screen and (max-width: 850px) {
  .footer {
    font-size: 2.5vw;
  }
  .row {
    padding: 0 0.5rem;
  }
  .member img {
    width: 90px;
    height: 90px;
  }
  .landing--background-video {
    display: none;
  }

  .landing--background-video-mobile {
    display: unset;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    opacity: 0.9;
  }
}

@media only screen and (max-width: 370px) {
  .footer {
    font-size: 2.5vw;
  }
  .row {
    padding: 0 0.5rem;
  }
  .col {
    padding: 5px 20px 20px 20px;
  }
  .member img {
    height: 60px;
    width: 60px;
  }
  .member-info {
    font-size: calc(max(2vw, 17px));
    margin: auto 0 auto 0;
  }
}
// legal

.legal {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: black;
}

.legal h1 {
  margin-top: 0;
}